$(document).ready(function () {
	/* - - - - - - -        isValidEmail       - - - - - - - - */
	function isValidEmail(email) {
		return /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(
			email
		);
	}

	/* - - - - - - -        valid subscribe       - - - - - - - - */
	$(document).on("blur", '.subscribe [type="email"]', function () {
		const email = $(this).val();
		if (email === "") {
			$(this).parent().removeClass("has-error has-success");
		} else if (isValidEmail(email)) {
			$(this).parent().removeClass("has-error").addClass("has-success");
		} else {
			$(this).parent().removeClass("has-success").addClass("has-error");
			$(this)
				.closest("form")
				.submit(function (e) {
					e.stopPropagation();
				});
		}
	});

	/* - - - - - - -       mCustomScrollbar       - - - - - - - - */
	$(".js-scrollbar").mCustomScrollbar({
		alwaysShowScrollbar: 2,
		scrollInertia: 400,
	});
	$(".js-scrollbar-horizontal").mCustomScrollbar({
		scrollInertia: 400,
		axis: "x",
		theme: 'dark',
		advanced: { autoExpandHorizontalScroll: true },
		documentTouchScroll: true,
		contentTouchScroll: true
	});
	$('.js-scrollbar-horizontal-left').on('click', function() {
		$(".js-scrollbar-horizontal").mCustomScrollbar("scrollTo","+=200");
	})
	$('.js-scrollbar-horizontal-right').on('click', function() {
		$(".js-scrollbar-horizontal").mCustomScrollbar("scrollTo","-=200");
	})
	//- - - - - - - - - - - - - - - - - - - - - -            init select2             - - - - - - - - - - - - - - - - - - - - - -
	$(".js-select").select2({
		language: "uk",
		width: "100%",
		minimumResultsForSearch: -1,
		dropdownParent: $(".main"),
	});
	$(".js-select-xs").select2({
		language: "uk",
		width: "100%",
		minimumResultsForSearch: -1,
		containerCssClass: "select-xs",
		dropdownCssClass: "select-xs-dropdown",
		dropdownParent: $(".main"),
	});

	/* - - - - - - -       header fixed       - - - - - - - - */
	function fixedHeader() {
		const top = $(window).scrollTop();
		if (top > 0) {
			$(".header").addClass("fixed");
		} else {
			$(".header").removeClass("fixed");
		}
	}
	fixedHeader();
	$(window).on("scroll", function () {
		fixedHeader();
	});

	/* - - - - - - -       header toggle       - - - - - - - - */
	$(document).on("click", ".js-header-toggle", function (e) {
		e.preventDefault();
		$(".header").toggleClass("show");
	});

	/* - - - - - - -       header rebuild       - - - - - - - - */
	function rebuildHeader() {
		const $headerPhone = $(".header-phone").detach();
		const $headerLang = $(".lang").detach();
		const $headerWish = $(".header-wish").detach();
		const $headerUser = $(".header-user").detach();
		const $headerLocation = $(".header-location").detach();

		if (window.matchMedia("(max-width: 1199px)").matches) {
			$headerPhone.appendTo(".header-modal__panel");
			$headerLang.appendTo(".header-modal__panel");
			$headerWish.appendTo(".header-modal__panel");
			$headerUser.appendTo(".header-modal__panel");
			$headerLocation.appendTo(".header-modal__panel");
		} else {
			$headerLocation.appendTo(".header-feedback");
			$headerPhone.appendTo(".header-feedback");
			$headerLang.appendTo(".header-lang");
			$headerWish.appendTo(".header-panel");
			$headerUser.appendTo(".header-panel");
		}
	}
	$(window).on("resize", function () {
		rebuildHeader();
	});
	rebuildHeader();

	/* - - - - - - -       top-menu box toggle       - - - - - - - - */
	$(document).on("click", ".js-top-menu-box-toggle", function () {
		const $parent = $(this).closest(".top-menu__box");
		if (!$parent.hasClass("show")) {
			$parent.addClass("show");
			$parent.find(".top-menu__box-list").slideDown(300);
		} else {
			$parent.removeClass("show");
			$parent.find(".top-menu__box-list").slideUp(300);
		}
	});
	$(window).on("resize", function () {
		if (window.matchMedia("(min-width: 1200px)").matches) {
			$(".top-menu__box").removeClass("show");
			$(".top-menu__box-list").css("display", "");
		}
	});

	/* - - - - - - -       footer menu box toggle       - - - - - - - - */
	$(document).on("click", ".js-footer-menu-toggle", function () {
		const $parent = $(this).closest(".footer-menu__box");
		if (!$parent.hasClass("show")) {
			$parent.addClass("show");
			$parent.find(".footer-menu__box-list").slideDown(300);
		} else {
			$parent.removeClass("show");
			$parent.find(".footer-menu__box-list").slideUp(300);
		}
	});
	$(window).on("resize", function () {
		if (window.matchMedia("(min-width: 768px)").matches) {
			$(".footer-menu__box").removeClass("show");
			$(".footer-menu__box-list").css("display", "");
		}
	});

	/* - - - - - - -       scroll to       - - - - - - - - */
	$(document).on("click", ".js-scroll-to", function (e) {
		e.preventDefault();
		const link = $(this).attr("href");
		$("html, body").animate({ scrollTop: $(link).offset().top }, 300);
	});

	/* - - - - - - -        head slider       - - - - - - - - */
	$(".js-head-slider").slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: false,
		swipe: true,
		swipeToSlide: false,
		touchMove: false,
		pauseOnFocus: false,
		pauseOnHover: false,
		speed: 600,
		autoplay: true,
		autoplaySpeed: 4000,
	});

	/* - - - - - - -        blog slider       - - - - - - - - */
	$(".js-main-blog-slider").slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		swipeToSlide: true,
		speed: 300,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					adaptiveHeight: true,
				},
			},
		],
	});

	/* - - - - - - -        stage slider       - - - - - - - - */
	$(".js-main-catalog-slider").slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		swipeToSlide: true,
		speed: 300,
		responsive: [
			{
				breakpoint: 1366,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					adaptiveHeight: true,
				},
			},
		],
	});

	/* - - - - - - -        wish toggle       - - - - - - - - */
	// $(document).on("click", ".js-wish, .js-cart", function () {
	// 	$(this).addClass("active");
	// });

	$(document).on("click", ".js-cart", function () {	
		if(!$(this).hasClass('active')){
			$('.header-add').css('display','none')
			let modal = $('#inBascket')
			let close = $(modal).find('.modal_close')
			$(modal).css('display','block')
			$(close).on("click", function() {
				$(modal).css('display','none')
			})
			setTimeout(() => $(modal).css('display','none'), 4000)
		}
		$(this).addClass("active");
	})

	$(document).on("click", ".js-wish", function () {
		if(!$(this).hasClass('active')){
			$('.header-add').css('display','none')
			let modal = $('#inWish')
			let close = $(modal).find('.modal_close')
			$(modal).css('display','block')
			$(close).on("click", function() {
				$(modal).css('display','none')
			})
			setTimeout(() => $(modal).css('display','none'), 4000)
		}
		$(this).addClass("active");
	})


	/* - - - - - - -        more toggle       - - - - - - - - */
	$(document).on("click", ".js-more", function (e) {
		e.preventDefault();
		const $this = $(this);
		if (!$this.hasClass("active")) {
			$this.addClass("active");
			$('[data-more="' + $this.attr("data-more") + '"]')
				.not($this)
				.addClass("show");
		} else {
			$this.removeClass("active");
			$('[data-more="' + $this.attr("data-more") + '"]')
				.not($this)
				.removeClass("show");
		}
	});

	function toggleFilterType($this) {
		if (!$this.hasClass("show")) {
			$this.addClass("show");
			$this.find(".catalog-filter__box-list").slideDown(300);
		} else {
			$this.removeClass("show");
			$this.find(".catalog-filter__box-list").slideUp(300);
		}
	}

	$(document).on("click", ".js-filter-type", function () {
		const $parent = $(this).closest(".catalog-filter__box");
		toggleFilterType($parent);
	});

	$(".catalog-filter__box").each(function () {
		const $this = $(this);
		if (!$this.hasClass("show")) {
			$this.find(".catalog-filter__box-list").slideUp(300);
		} else {
			$this.find(".catalog-filter__box-list").slideDown(300);
		}
	});

	/* - - - - - - - - - - - - - -       catalog filter change       - - - - - - - - - - - - - - - */
	// function getCatalogOption($this) {
	// 	const text = $this.next("label").text();
	// 	if ($this.prop("checked")) {
	// 		$(".catalog-panel__list").append(
	// 			'<div class="filter-tag" data-type="' +
	// 				$this.attr("id") +
	// 				'">' +
	// 				'<span class="filter-tag__title">' +
	// 				text +
	// 				"</span>" +
	// 				'<span class="icon icon-i-close-fill js-catalog-remove"></span>' +
	// 				"</div>"
	// 		);
	// 	} else {
	// 		$(".catalog-panel__list")
	// 			.find('[data-type="' + $this.attr("id") + '"]')
	// 			.remove();
	// 	}
	// }
	// $(document).on(
	// 	"change",
	// 	'.catalog-filter__box [type="checkbox"]',
	// 	function () {
	// 		getCatalogOption($(this));
	// 	}
	// );
	// $('.catalog-filter__box [type="checkbox"]').each(function () {
	// 	getCatalogOption($(this));
	// });
	// $(document).on("click", ".js-catalog-remove", function () {
	// 	const $parent = $(this).closest(".filter-tag");
	// 	$("#" + $parent.attr("data-type")).prop("checked", false);
	// 	$parent.remove();
	// });
	// $(document).on("click", ".js-catalog-reset", function (e) {
	// 	e.preventDefault();
	// 	$('.mfilter-button-reset').trigger("click")
	// 	$(".catalog-panel__list").text("");
	// 	$('.catalog-filter__box [type="checkbox"]').prop("checked", false);
	// 	$(".catalog-panel__sort select").val(0).trigger("change");
	// 	if ($(".catalog-filter .js-range").length) {
	// 		const filterRangeSlider = $(".catalog-filter .js-range");
	// 		filterRangeSlider.data("ionRangeSlider").reset();
	// 		$(".range-box")
	// 			.find(".range-box__value-from")
	// 			.val(prettify(filterRangeSlider.attr("data-from")));
	// 		$(".range-box")
	// 			.find(".range-box__value-to")
	// 			.val(prettify(filterRangeSlider.attr("data-to")));
	// 	}
	// });


	$(document).on("click", ".js-filter-toggle", function (e) {
		e.preventDefault();
		$(".catalog-filter").toggleClass("show");
	});
	$(document).on("click", ".js-close-filter", function () {
		$(".catalog-filter").removeClass("show");
	});
	$(document).on("click", ".js-filter-toggle", function (e) {
		e.preventDefault();
		$(".mfilter-content").toggleClass("show");
	});
	$(document).on("click", ".js-close-filter", function () {
		$(".mfilter-content").removeClass("show");
	});
	// function rebuildFilter() {
	// 	const $filterReset = $(".catalog-panel__reset").detach();
	// 	if (window.matchMedia("(max-width: 1199px)").matches) {
	// 		$filterReset.appendTo(".catalog-filter__buttons");
	// 	} else {
	// 		$filterReset.prependTo(".catalog-panel");
	// 	}
	// }
	function rebuildFilter() {
		const $filterReset = $(".catalog-panel__reset").detach();
		if (window.matchMedia("(max-width: 1199px)").matches) {
			$filterReset.appendTo(".mfilter-content");
		} else {
			$filterReset.prependTo(".catalog-panel");
		}
	}
	
	$(window).on("resize", function () {
		rebuildFilter();
	});
	rebuildFilter();

	/* - - - - - - - - - - - - - -       prettify       - - - - - - - - - - - - - - - */
	function prettify(num) {
		const n = num.toString();
		return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + " ");
	}

	/* - - - - - - - - - - - - - -       init ionRangeSlider       - - - - - - - - - - - - - - - */
	$(".js-range").each(function () {
		const $range = $(this),
			$parent = $range.closest(".range-box"),
			$inputFrom = $parent.find(".range-box__value-from"),
			$inputTo = $parent.find(".range-box__value-to"),
			min = parseInt($range.attr("data-min")),
			max = parseInt($range.attr("data-max")),
			valueFrom = parseInt($range.attr("data-from")),
			valueTo = parseInt($range.attr("data-to"));
		let instance;
		$range.ionRangeSlider({
			type: "double",
			grid: false,
			hide_from_to: true,
			hide_min_max: true,
			// min: min,
			// max: max,
			// from: valueFrom,
			// to: valueTo,
			onStart: updateInputs,
			onChange: updateInputs,
		});
		instance = $range.data("ionRangeSlider");
		function updateInputs(data) {
			const from = data.from,
				to = data.to;
			$inputFrom.prop("value", prettify(from));
			$inputTo.prop("value", prettify(to));
		}
		$inputFrom.on("input", function () {
			let val = parseInt($(this).prop("value").replace(/\s+/g, ""));
			const to = parseInt(
				$(this)
					.closest(".range-box")
					.find(".range-box__value-to")
					.prop("value")
					.replace(/\s+/g, "")
			);
			// validate
			if (val <= min) {
				val = min;
			} else if (val >= to) {
				val = to;
			}
			instance.update({
				from: val,
			});
			// $inputFrom.val( prettify( val ) );
		});
		$inputFrom.on("blur", function () {
			let val = parseInt($(this).prop("value").replace(/\s+/g, ""));
			const to = parseInt(
				$(this)
					.closest(".range-box")
					.find(".range-box__value-to")
					.prop("value")
					.replace(/\s+/g, "")
			);
			if (val <= min || !val) {
				val = min;
			} else if (val >= to) {
				val = to;
			}
			$inputFrom.val(prettify(val));
		});
		$inputTo.on("input", function () {
			let val = parseInt($(this).prop("value").replace(/\s+/g, ""));
			const from = parseInt(
				$(this)
					.closest(".range-box")
					.find(".range-box__value-from")
					.prop("value")
					.replace(/\s+/g, "")
			);
			// validate
			if (val < from) {
				val = from;
			} else if (val > max) {
				val = max;
			}
			instance.update({
				to: val,
			});
			// $inputTo.val( prettify( val ) );
		});
		$inputTo.on("blur", function () {
			let val = parseInt($(this).prop("value").replace(/\s+/g, ""));
			const from = parseInt(
				$(this)
					.closest(".range-box")
					.find(".range-box__value-from")
					.prop("value")
					.replace(/\s+/g, "")
			);
			if (val >= max || !val) {
				val = max;
			} else if (val <= from) {
				val = from;
			}
			$inputTo.val(prettify(val));
		});
	});

	/*--------------- match height ----------------------------*/
	$(function () {
		$(".blog-box__title").matchHeight({
			byRow: true,
			property: "height",
			target: null,
			remove: false,
		});
	});

	/*--------- Product card slider -----------*/
	// $(function () {
	// 	$(".product_card_main_imgs-preview").on("click", function (e) {
	// 		e.preventDefault();
	// 		const $target = $(e.target);
	// 		if ($target.is("img")) {
	// 			const targetSrc = $target.attr("src");
	// 			// const mainImg = $('.product_card_main_imgs-main').find(`img[data-img='main']`)
	// 			// const mainLink = $('.product_card_main_imgs-main').find(`[data-link='main']`)

	// 			const targetData = $target.data("number");
	// 			let dotTarget = $(".product_card_main_imgs_dots-list").find(
	// 				"li"
	// 			)[targetData];

	// 			$(".product_card_main_imgs-preview")
	// 				.find("img")
	// 				.removeClass("active");
	// 			$(".product_card_main_imgs_dots-list")
	// 				.find("li")
	// 				.removeClass("active");

	// 			$(dotTarget).addClass("active");
	// 			$target.addClass("active");
	// 			// mainImg[0].setAttribute('src', targetSrc)
	// 			// mainLink[0].setAttribute('href', targetSrc)
	// 			const img = $(`[data-img='main']`);
	// 			$(img).each(function () {
	// 				$(this).removeClass("active");
	// 			});
	// 			$(img[targetData]).addClass("active");
	// 		}
	// 	});
	// });
	// $(function () {
	// 	$(".product_card_main_imgs_dots-list").on("click", function (e) {
	// 		e.preventDefault();

	// 		let $target = $(e.target);
	// 		if ($target.is("li")) {
	// 			// const mainImg = $('.product_card_main_imgs-main').find(`img[data-img='main']`)
	// 			// const mainLink = $('.product_card_main_imgs-main').find(`[data-link='main']`)
	// 			const targetData = $target.data("number");
	// 			let imgTarget = $(".product_card_main_imgs-preview").find(
	// 				"img"
	// 			)[targetData];
	// 			// let imgTargetSrc  = $(imgTarget).attr('src')
	// 			$(".product_card_main_imgs-preview")
	// 				.find("img")
	// 				.removeClass("active");
	// 			$(".product_card_main_imgs_dots-list")
	// 				.find("li")
	// 				.removeClass("active");

	// 			$(imgTarget).addClass("active");
	// 			$target.addClass("active");
	// 			// mainImg[0].setAttribute('src', imgTargetSrc)
	// 			// mainLink[0].setAttribute('href', imgTargetSrc)
	// 			const img = $(`[data-img='main']`);
	// 			$(img).each(function () {
	// 				$(this).removeClass("active");
	// 			});
	// 			$(img[targetData]).addClass("active");
	// 		}
	// 	});
	// });
	/*---------------- Product card fancybox -----------------*/

	$('[data-fancybox="images"]').fancybox({
		// Options will go here
		loop: true,
		infobar: false,
		smallBtn: "auto",
		width: 500,
		height: 500,
	});

	/*---------------- Product card wish ----------------*/
	$(function () {
		$(".in_wish").on("click", function (e) {
			e.preventDefault();
			const $parent = $(this).closest(".product_card_main_desc_wish");
			if ($parent.hasClass("active")) {
				// $parent.removeClass("active");
			} else {
				$parent.addClass("active");
				$('.header-add').css('display','none')
				let modal = $('#inWish')
				let close = $(modal).find('.modal_close')
				$(modal).css('display','block')
				$(close).on("click", function() {
					$(modal).css('display','none')
				})
				setTimeout(() => $(modal).css('display','none'), 4000)
			}
		});
	});

	/*--------------- Product counter ----------------*/
	$(function () {
		$(".counter_block").on("click", function (e) {
			e.preventDefault();
			const $target = $(e.target);
			const $parent = $target.closest(".counter_block");
			
			let $counter = $parent.find(`[data-context='counter']`);
			let $counterValue = $counter.html().trim();
			let $targetData = $target.data("context");
			if (!$targetData) {
				$targetData = $target.parent().data("context");
			}
			if ($targetData == "minus") {
				if (Number($counterValue) > 1) {
					$counter.html(`${Number($counterValue) - 1}`);
				}
			} else if ($targetData == "plus") {
				$counter.html(`${Number($counterValue) + 1}`);
			}
		});
	});

	/*---------------------  Product card tabs ------------------*/
	
	let Tab = (class_name) => {
		$(`${class_name}_tab_chose_list`).on("click", function (e) {
			e.preventDefault();

			const $target = $(e.target);
			if ($target.is("li")) {
				const id = $target.data("tab");
				const content = $(
					`${class_name}_tab_result_item[data-tab=${id}]`
				);
				$(`${class_name}_tab_chose_item.active`).removeClass("active");
				$target.addClass("active");

				$(`${class_name}_tab_result_item.active`).removeClass(
					"active"
				);
				content.addClass("active");
			}
		});
	} 
	Tab('.product_card')
	/*------------------ Product modul in bascket --------------*/
	$(function () {
		$(".in_basket").on("click", function (e) {
			e.preventDefault();
			if(!$(this).hasClass('active')){
				$(this).addClass('active')
				$('.header-add').css('display','none')
				let modal = $('#inBascket')
				let close = $(modal).find('.modal_close')
				$(modal).css('display','block')
				$(close).on("click", function() {
					$(modal).css('display','none')
				})
				setTimeout(() => $(modal).css('display','none'), 4000)
			}
		});
	});

	/*------------- Google map -----------------*/

	/*------------- Delivery tabs -------------*/
	/* 585 */
	Tab('.delivery')
	

	/*--------------- Contact input placeholder --------*/
	$(function () {
		$('.custom_placeholder').on('click', function(e) {	
			// targetData = $.this.find('.placeholder')
			let placeholder = this.querySelector('.placeholder')
			let placeholderName = placeholder.getAttribute('data-name')
			let input = $(`[name="${placeholderName}"]`)[0]
			input.focus()
		});

		function checkNull(input) {
			let $this = $(input)
			let name = $this.attr('name')
			let placeholder = $(`.placeholder[data-name="${name}"]`)
			if($('*').is($this) && $(input).val().trim() != 0) {
				$(placeholder).css('opacity','0')
			}
			$(input).on('change', function(e) {
				if($this.val().trim() != 0) {
					$(placeholder).css('opacity','0')
				}
			})
			$(input).on('keyup', function(e) {
				if($this.val().trim() == 0) {
					$(placeholder).css('opacity','0.5')
				} else {
					$(placeholder).css('opacity','0')
				}
			})
		}
		if($('*').is('input[name="first_name"]')) {
			checkNull('input[name="first_name"]')
		}
		if($('*').is('input[name="first_name"]')) {
			checkNull('input[name="second_name"]')
		}
		if($('*').is('input[name="first_name"]')) {
			checkNull('input[name="email"]')
		}
		if($('*').is('input[name="first_name"]')) {
			checkNull('textarea[name="enquiry"]')
		}
	});


	// 
	$(document).on("click", ".js-catalog-reset", function (e) {
		e.preventDefault();
		$('.mfilter-button-reset').trigger("click")
	});
	

	/*-------- input check ----*/
	function getCatalogOption($this) {
		
		const $parent = $this.closest('.mfilter-col-input')
		const text = $parent.next("label").text();
		if ($this.prop("checked")) {
			$(".catalog-panel__list").append(
				'<div class="filter-tag" data-type="' +
					$this.attr("id") +
					'">' +
					'<span class="filter-tag__title">' +
					text +
					"</span>" +
					'<span class="icon icon-i-close-fill js-catalog-remove"></span>' +
					"</div>"
			);
		} else {
			$(".catalog-panel__list")
				.find('[data-type="' + $this.attr("id") + '"]')
				.remove();
		}
	}

	
	$('.mfilter-box [type="checkbox"]').each(function () {
		getCatalogOption($(this));
	});
	$(document).on("click", ".js-catalog-remove", function () {
		const $parent = $(this).closest(".filter-tag");
		$("#" + $parent.attr("data-type")).trigger('click');
		$parent.remove();
	});

	$(document).on(
		"change",
		'.mfilter-box [type="checkbox"]',
		function () {
			getCatalogOption($(this));
		}
	);

	/*---------- modal ------------*/
	
	$(".various").click(function (e) {
		e.preventDefault();
		let href = $(this).data('href');
		parent.$.fancybox.close();
		$.fancybox.open({
			src			: href,	
			fitToView	: true,
			smallBtn	: false,
			autoHeight	: true,
			touch		: false,
			buttons		: [],
			autoFocus:false,
			slideClass : `${href.slice(1)}`
		})
	})

	$(document).on("blur", '.modal_login [type="email"]', function () {
		const email = $(this).val();
		if (email === "") {
			$(this).parent().removeClass("has-error has-success");
		} else if (isValidEmail(email)) {
			$(this).parent().removeClass("has-error").addClass("has-success");
		} else {
			$(this).parent().removeClass("has-success").addClass("has-error");
			$(this)
				.closest("form")
				.submit(function (e) {
					e.stopPropagation();
				});
		}
	});
	$(document).on("blur", '.modal_reset-password [type="email"]', function () {
		const email = $(this).val();
		if (email === "") {
			$(this).parent().removeClass("has-error has-success");
		} else if (isValidEmail(email)) {
			$(this).parent().removeClass("has-error").addClass("has-success");
		} else {
			$(this).parent().removeClass("has-success").addClass("has-error");
			$(this)
				.closest("form")
				.submit(function (e) {
					e.stopPropagation();
				});
		}
	});

	Tab('.login')
	/*---------------- Search ----------------*/
	function inputIcon(input) {
		$(input).on('keyup', function(e) {
			let $this = $(e.target)
			let $divIcon = $this.next('div[data-type="search-icon"]')
			if($this.val().trim() != 0){
				if($divIcon.hasClass('icon_search')){
					$divIcon.removeClass('icon_search')
					$divIcon.addClass('icon_delete')
				}
			} else {
				if($divIcon.hasClass('icon_delete')){
					$divIcon.removeClass('icon_delete')
					$divIcon.addClass('icon_search')
				}
			}
		})
		
		let $divIcon = $(input).next('div[data-type="search-icon"]')
		$divIcon.on('click', function(e) {
			let $this = $(e.target)
			if($this.hasClass('icon-i-close')) {
				$this.parent().parent().find('input[data-type="search-input"]').val('')
				$divIcon.removeClass('icon_delete')
				$divIcon.addClass('icon_search')
			}
		})

	}
	inputIcon('input[data-type="search-input"]')
	$(document).on('click','.js-search',function(e){
		e.preventDefault();
		var height=$(window).height()-$('.header').innerHeight();
		if(!$('.search').hasClass('show')){
			$('body').addClass('no-scroll');
			$('html, body').animate({'scrollTop':0},300);
			$('.header').css('background','#fff')
			$('.search').addClass('show').height(height);
		} else {
			$('body').removeClass('no-scroll');
			$('html, body').animate({'scrollTop':0},300);
			$('.header').css('background','transparent')
			$('.search').removeClass('show').height(0);
		}	
	});
	$(document).on('click','.js-modal-search-close',function(){
		$('body').removeClass('no-scroll');
		$('.search').removeClass('show').height(0);
	});
	$(function() {
		if(localStorage.getItem('cookie') == null) {
			$('.cookie').css('height','auto')
			let heightBanner = $('.cookie').innerHeight();
			let headMarginTop = $('.main').css('margin-top');
			$('.main').css('margin-top',`${+headMarginTop.slice(0,headMarginTop.length-2)+heightBanner}px`)
			let cookieBloc = $('.cookie')
			let btnAgree = cookieBloc.find('.cookie_btn')
			let btnClose = cookieBloc.find('.cookie_close')
			btnAgree.on('click', function() {
				localStorage.setItem('cookie','true')
				cookieBloc.css('height','0')
				$('.main').css('margin-top',`${headMarginTop}`)
			})
			btnClose.on('click', function() {
				localStorage.setItem('cookie','false')
				cookieBloc.css('height','0')
				$('.main').css('margin-top',`${headMarginTop}`)
			})
			$(window).on('resize', function () {
				let heightBanner = $('.cookie').innerHeight();
				$('.main').css('margin-top',`${+headMarginTop.slice(0,headMarginTop.length-2)+heightBanner}px`)
			})
		}
		if(localStorage.getItem('cookie')) {
			$('.cookie').css('height','0')
		} 
	})

	/*--- product card slider -------*/
	$('.product_card_main_imgs-list').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		dots: true,
		asNavFor: '.product_card_main_imgs-preview',
		responsive: [
			{
				breakpoint: 991,
				settings: {
					dots: false,
				}
			},
		]
	});


	$('.product_card_main_imgs-preview').slick({
		vertical: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		asNavFor: '.product_card_main_imgs-list',
		arrows: false,
		dots: false,
		centerMode: false,
		infinity: true,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					vertical: false,
				}
			},
		]
	});
	
	$(document).on("click", ".catalog-panel__reset", function (e) {
		e.preventDefault();
		$(".catalog-panel__list").text("");
		$('.js-catalog-reset').trigger()
	});
	
	/*--------------- mask ---------------*/
	$('.mask_phone').inputmask('+38 (099) 999-99-99')
	$('#customer_telephone').inputmask('+38 (099) 999-99-99')
	
	$('#click-buy-modal').on('click', function (e) {
		e.preventDefault()
	})

	// $('.test').on('click', function() {
	// 	$('#triggerFinishBuy').click()
	// })

	$('.contact_work-with_item_wrapper').matchHeight({byRow: true,});

});

$('#custom_payment-select').select2({
	minimumResultsForSearch: -1,
	dropdownParent: $("#simple_container"),
});
$('#shipping_address_address_2').select2({
	minimumResultsForSearch: -1,
	dropdownParent: $("#simple_container"),
});
//-----------------  personal page aside script ----------------//
function fixedAside() {
  if($('*').is('#personal_cabinet-custom_field')) {
		if (window.matchMedia("(min-width: 577px)").matches) {
			const top = $(window).scrollTop();
			let footerPosition = $(".footer").offset().top
			let asideHeight = parseInt($("#column-right .list-group").css('height'))
			let headerHeight = parseInt($('.header-inner').css('height'))
			let paddingTop = parseInt($('#content h1').css('height')) + parseInt($('.breadcrumbs').css('height'))
			let paddingBottom =  $('*').is('#personal_cabinet-custom_field') ? parseInt($('#personal_cabinet-custom_field').css('padding-bottom')) : 0
			let tableMarginBottom = $('*').is('.table') ? parseInt($('.table').css('margin-bottom')) : 0
			let buttonsMarginBottom =  $('*').is('.buttons') ? parseInt($('.buttons').css('margin-bottom')) : 0
			if (top > paddingTop) {
				$("#column-right .list-group").addClass("fixed");
				if(top < (footerPosition - tableMarginBottom - paddingBottom - asideHeight - headerHeight - buttonsMarginBottom)) {
					$("#column-right .list-group").css('top',`${top-52}px`)
				}
			} else {
				$("#column-right .list-group").removeClass("fixed");
			}
		} else {
			$("#column-right .list-group").removeClass("fixed");
		}
	}
}
fixedAside();
$(window).on("scroll", function () {
  fixedAside();
});
$(window).on("resize", function () {
  fixedAside();
});

$('.aside__burger a').on('click', function(e) {
	e.preventDefault()
	if($('#column-right').hasClass('show')){
		$('#column-right').removeClass('show')
		$('#content').removeClass('content__shadow')
	} else {
		$('#column-right').addClass('show')
		$('#content').addClass('content__shadow')
	}
})
$('.content__shadow').on('click', function(e) {
	e.preventDefault()
	if($('#column-right').hasClass('show')){
		$('#column-right').removeClass('show')
		$('#content').removeClass('content__shadow')
	} else {
		$('#column-right').addClass('show')
		$('#content').addClass('content__shadow')
	}
})